<template>
  <div class="m-spots-list">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2 class="m-spots-list__headline">
            {{ headline }}
          </h2>
        </div>

        <div class="col-md-6 d-flex justify-content-end">
          <base-dropdown
            v-if="tags && tags.length > 0"
            :title="$t('SpotsFilterDropdown', 'Filter i kategorier')"
            :options="tags"
            :selected="selected || []"
            class="-filter"
            @onSelect="onOptionSelected"
            @onClear="onClearFilter"
          />
        </div>
        <div v-if="selected && selected.length" class="col-12">
          <span v-for="tag in selected" :key="tag.key" class="a-button -primary -small mr-2 mb-2 pr-4 d-inline-flex align-items-center" @click="onOptionSelected(tag)">
            {{ tag.value }}
            <base-icon icon="close" class="ml-3" />
          </span>
        </div>
      </div>
      <div class="row">
        <div v-for="(spot, i) in spots" :key="i" class="col-md-6 col-lg-4 mb-6 mt-6">
          <spot-item-list :spot-item="spot" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseDropdown from '@/components/atoms/BaseDropdown';
import BaseIcon from '@/components/atoms/BaseIcon';
import SpotItemList from '@/components/molecules/SpotItemList';
export default {
  name: 'SpotsList',
  components: {
    BaseDropdown,
    SpotItemList,
    BaseIcon
  },
  props: {
    spots: {
      type: Array,
      default: () => {}
    },
    tags: {
      type: Array,
      default: () => {}
    },
    selected: {
      type: Array,
      default: () => {}
    },
    headline: {
      type: String,
      default: '',
    }
  },
  methods: {
    onOptionSelected (item) {
      this.$emit('onTagSelected', item);
    },
    onClearFilter () {
      this.$emit('onTagClear');
    }
  }
}
</script>